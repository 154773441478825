<template>
  <section>
    
    <b-overlay :show="loading" rounded="sm">
      <button
        :disabled="checkDisabled"
        v-on:click="loginSubmit"
        class="btn btn-fill mt-2 btn-block btn-primary"
      >
        {{ $t("LOGIN.SUBMIT") }}
      </button>
      
    </b-overlay>
  </section>
</template>
<script>
export default {
  props: ["loginInfo"],
  data() {
    return {
      loading: true,
      auth_uuid: "",
      errorMessage: "",
    };
  },
  methods: {
    loginSubmit: async function(event) {
      event.preventDefault();
      this.loading = true;
      let self = this;
      this.$http
        .post(self.user.hostname + "/authenticate", {
          email: this.user.email,
          auth_type: this.loginInfo.auth_type,
          auth_method: this.loginInfo.auth_method,
          client: "addin"
        })
        .then(function(result) {
          let redirect = false;
          if(result.data.redirect != undefined)
          {
            if(result.data.redirect == 1)
            {
              redirect = true;
            }
          }
          if(redirect)
          {
            self.$emit('startAuthentication',result.data.request_url);
          }else{
            self.auth_uuid = result.data.auth_uuid;
            self.makeRequest(result.data.request_url);
          }
        })
        .catch(function(error) {
          self.$emit("loading", false);
          self.loading = false;
          if(error.toString() == "Error: fail_4_4") return;
          self.$noty.error(self.$t("ERROR.CANNOT_LOGIN_WITH_CERTIFICATE"));
        });
    },
    makeRequest: function(url) {
      this.loading = true;
      let self = this;
      this.$http
        .get(url)
        .then(function() {
          self.verifyAuthentication();
        })
        .catch(function(error) {
          self.loading = false;
          if(error.toString() == "Error: fail_4_4") return;
          self.$noty.error(self.$t("ERROR.CANNOT_LOGIN_WITH_CERTIFICATE"));
        });
    },
    verifyAuthentication: function() {
      let self = this;
      this.$http
        .get(self.user.hostname + "/authenticate/result/" + this.auth_uuid)
        .then(function(result) {
          self.$emit("authenticated", result.data.token);
        })
        .catch(function(error) {
          self.$emit("loading", false);
          self.loading = false;
          if(error.toString() == "Error: fail_4_4") return;
          self.$noty.error(self.$t("ERROR.CANNOT_LOGIN_WITH_CERTIFICATE"));
        });
    },
  },
  computed: {
    checkDisabled: function() {
      return this.loginInfo.email == "";
    },
  },
  mounted: function() {
    this.loading = false;
  },
};
</script>
<style></style>
